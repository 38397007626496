import React, { useRef, useState, useEffect } from 'react';
import {
    FaMedal,
    FaMobileAlt,
    FaCog,
    FaPrint,
    FaGlobe,
    FaShoppingCart,
    FaCreditCard,
    FaTruck,
    FaUtensils,
    FaChartBar,
    FaChartLine,
} from 'react-icons/fa';

const Timeline = () => {
    const timelineItems = [
        {
            id: 1,
            title: 'MENU DIGITALE',
            description:
                'QR-Code, sempre aggiornabile, no pubblicità, allergeni, traduzioni «manuali», ingredienti e filtri',
            date: 'Ott-24',
            color: 'bg-blue-500',
            icon: <FaMedal className="text-white" size={24} />,
        },
        {
            id: 2,
            title: 'APP',
            description:
                'Applicazione per ricercare tra i menu dei ristoranti con filtri avanzati di ingredienti e allergeni',
            date: 'Feb-25',
            color: 'bg-orange-500',
            icon: <FaMobileAlt className="text-white" size={24} />,
        },
        {
            id: 3,
            title: 'FUNZIONI AVANZATE',
            description:
                'Organizza contenuti, personalizza layout, prezzi multipli, galleria immagini, strumenti IA per compilazione',
            date: '',
            color: 'bg-green-500',
            icon: <FaCog className="text-white" size={24} />,
        },
        {
            id: 4,
            title: 'STAMPA MENU',
            description: 'Stampa menu in formato PDF',
            date: '',
            color: 'bg-pink-500',
            icon: <FaPrint className="text-white" size={24} />,
        },
        {
            id: 5,
            title: 'TRADUZIONI',
            description: 'Traduzioni automatiche del menu',
            date: '',
            color: 'bg-teal-500',
            icon: <FaGlobe className="text-white" size={24} />,
        },
        {
            id: 6,
            title: 'ORDINI',
            description: 'Gestione degli ordini',
            date: '',
            color: 'bg-yellow-500',
            icon: <FaShoppingCart className="text-white" size={24} />,
        },
        {
            id: 7,
            title: 'PAGAMENTI',
            description: 'Integrazione con i sistemi di pagamento',
            date: '',
            color: 'bg-blue-600',
            icon: <FaCreditCard className="text-white" size={24} />,
        },
        {
            id: 8,
            title: 'DELIVERY',
            description: 'Servizio di Delivery e Take Away',
            date: '',
            color: 'bg-red-500',
            icon: <FaTruck className="text-white" size={24} />,
        },
        {
            id: 9,
            title: 'TAVOLI',
            description: 'Gestione della sala e dei tavoli',
            date: '',
            color: 'bg-green-600',
            icon: <FaUtensils className="text-white" size={24} />,
        },
        {
            id: 10,
            title: 'STATISTICHE',
            description:
                'Statistiche avanzate dei prodotti più venduti, delle visite al menu, statistiche delle ricerche utente in App',
            date: '',
            color: 'bg-purple-500',
            icon: <FaChartBar className="text-white" size={24} />,
        },
        {
            id: 11,
            title: 'GESTIONALE DI CASSA',
            description: 'Integrazione con i gestionali di cassa',
            date: '',
            color: 'bg-indigo-500',
            icon: <FaChartLine className="text-white" size={24} />,
        },
    ];


    // Riferimenti agli elementi DOM
    const mainTimelineRef = useRef(null);
    const timelineBarRef = useRef(null);
    const cursorRef = useRef(null);

    // Stato per la posizione del cursore
    const [cursorPosition, setCursorPosition] = useState(0);

    // Stato per tracciare il drag
    const [isDragging, setIsDragging] = useState(false);

    // Effetto per sincronizzare la posizione del cursore con lo scroll della timeline principale
    useEffect(() => {
        const mainTimeline = mainTimelineRef.current;

        const handleScroll = () => {
            const scrollLeft = mainTimeline.scrollLeft;
            const maxScrollLeft = mainTimeline.scrollWidth - mainTimeline.clientWidth;
            const scrollRatio = maxScrollLeft === 0 ? 0 : scrollLeft / maxScrollLeft;
            const timelineBar = timelineBarRef.current;
            const barWidth = timelineBar.clientWidth;
            const newCursorPos = scrollRatio * barWidth;
            setCursorPosition(newCursorPos);
        };

        mainTimeline.addEventListener('scroll', handleScroll);

        return () => {
            mainTimeline.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Funzioni per gestire il drag del cursore
    const startDrag = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const onDrag = (e) => {
        if (!isDragging) return;

        const timelineBar = timelineBarRef.current;
        const barRect = timelineBar.getBoundingClientRect();

        let clientX;
        if (e.type === 'touchmove') {
            clientX = e.touches[0].clientX;
        } else {
            clientX = e.clientX;
        }

        let newPos = clientX - barRect.left;
        newPos = Math.max(0, Math.min(newPos, barRect.width));

        setCursorPosition(newPos);

        const scrollRatio = barRect.width === 0 ? 0 : newPos / barRect.width;
        const mainTimeline = mainTimelineRef.current;
        const maxScrollLeft = mainTimeline.scrollWidth - mainTimeline.clientWidth;
        mainTimeline.scrollLeft = scrollRatio * maxScrollLeft;
    };

    const stopDrag = () => {
        setIsDragging(false);
    };

    // Aggiungere e rimuovere event listeners per il drag
    useEffect(() => {
        if (isDragging) {
            window.addEventListener('mousemove', onDrag);
            window.addEventListener('mouseup', stopDrag);
            window.addEventListener('touchmove', onDrag);
            window.addEventListener('touchend', stopDrag);
        } else {
            window.removeEventListener('mousemove', onDrag);
            window.removeEventListener('mouseup', stopDrag);
            window.removeEventListener('touchmove', onDrag);
            window.removeEventListener('touchend', stopDrag);
        }

        return () => {
            window.removeEventListener('mousemove', onDrag);
            window.removeEventListener('mouseup', stopDrag);
            window.removeEventListener('touchmove', onDrag);
            window.removeEventListener('touchend', stopDrag);
        };
    }, [isDragging]);

    // Funzione per gestire i click sulla barra temporale
    const onBarClick = (e) => {
        const timelineBar = timelineBarRef.current;
        const barRect = timelineBar.getBoundingClientRect();
        let clientX;
        if (e.type === 'touchstart') {
            clientX = e.touches[0].clientX;
        } else {
            clientX = e.clientX;
        }

        let newPos = clientX - barRect.left;
        newPos = Math.max(0, Math.min(newPos, barRect.width));

        setCursorPosition(newPos);

        const scrollRatio = barRect.width === 0 ? 0 : newPos / barRect.width;
        const mainTimeline = mainTimelineRef.current;
        const maxScrollLeft = mainTimeline.scrollWidth - mainTimeline.clientWidth;
        mainTimeline.scrollLeft = scrollRatio * maxScrollLeft;
    };

    return (
        <div
            ref={mainTimelineRef}
            className="flex overflow-x-auto scrollbar-hide  bg-white "
        >
            {/* Timeline Principale Orizzontale */}
            <div className="relative">
                {/* Linea Temporale Sotto le Icone */}
                <div className="absolute top-16 left-0 w-full h-1 bg-gray-300"></div>

                <div
                    ref={mainTimelineRef}
                    className="flex  overflow-hidden scrollbar-hide p-4 bg-white rounded-lg shadow-md"
                >
                    {timelineItems.map((item) => (
                        <div key={item.id} className="flex-shrink-0 w-64">
                            <div className="flex flex-col items-center text-center relative">
                                {/* Data Sopra l'Icona */}
                                <div className="text-sm font-bold text-gray-700 mb-2">
                                    {item.date}
                                </div>

                                {/* Icona */}
                                <div
                                    className={`flex items-center justify-center w-12 h-12 ${item.color} rounded-full mb-2 relative z-10`}
                                >
                                    {item.icon}
                                </div>

                                {/* Titolo */}
                                <div
                                    className={`mt-2 px-4 py-2 ${item.color} text-white font-bold rounded`}
                                >
                                    {item.title}
                                </div>

                                {/* Descrizione */}
                                <p className="mt-2 text-sm text-gray-600">{item.description}</p>
                            </div>

                        </div>
                    ))}

                </div>

            </div>


            <div className="mt-8 hidden lg:block">
                <div
                    ref={timelineBarRef}
                    className="relative w-full h-4 bg-gray-200 rounded-full"
                    onClick={onBarClick}
                >

                </div>
            </div>
        </div>
    );
};

export default Timeline;
